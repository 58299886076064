import {
    BrandVariants,
    GriffelStyle,
    Theme,
    createDarkTheme,
    createLightTheme,
    makeStyles,
    themeToTokensObject,
    tokens,
} from '@fluentui/react-components';

export const semanticKernelBrandRamp: BrandVariants = {
    10: '#060103',
    20: '#261018',
    30: '#431426',
    40: '#591732',
    50: '#701A3E',
    60: '#861F4B',
    70: '#982C57',
    80: '#A53E63',
    90: '#B15070',
    100: '#BC627E',
    110: '#C6748B',
    120: '#CF869A',
    130: '#D898A8',
    140: '#E0AAB7',
    150: '#E8BCC6',
    160: '#EFCFD6',
};

const efkbBrandRamp: BrandVariants = {
    10: '#010404',
    20: '#0F1B1C',
    30: '#132D30',
    40: '#143A3F',
    50: '#15484E',
    60: '#13565D',
    70: '#0F656E',
    80: '#06747E',
    90: '#22828C',
    100: '#438F98',
    110: '#5C9CA4',
    120: '#74A9B0',
    130: '#8AB7BC',
    140: '#A1C4C9',
    150: '#B7D2D6',
    160: '#CDE0E2',
};

export const semanticKernelLightTheme: Theme & { colorMeBackground: string } = {
    ...createLightTheme(semanticKernelBrandRamp),
    colorMeBackground: '#e8ebf9',
};

export const semanticKernelDarkTheme: Theme & { colorMeBackground: string } = {
    ...createDarkTheme(semanticKernelBrandRamp),
    colorMeBackground: '#2b2b3e',
};

export const ekfbLightTheme: Theme & { 
    ekfbRed: string,
    ekfbTeal: string,
    ekfbGreen: string,
    ekfbBlue: string,
    ekfbGrey: string,
    ekfbPurple: string
 } = {
    ...createLightTheme(efkbBrandRamp),
    ekfbRed: '#ff0000',
    ekfbTeal: '#007B86',
    ekfbGreen: '#0DB02B',
    ekfbBlue: '#194981',
    ekfbGrey: '#525b5C',
    ekfbPurple: '#8B1F5A'
};

export const ekfbDarkTheme: Theme & { colorMeBackground: string } = {
    ...createDarkTheme(efkbBrandRamp),
    colorMeBackground: '#2b2b3e',
};

export const customTokens = themeToTokensObject(ekfbLightTheme);

export const Breakpoints = {
    xSmall: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (max-width: 430px)': style };
    },
    small: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (max-width: 744px)': style };
    },
    medium: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media screen and (max-width: 790px)': style };
    },
    large: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media screen and (max-width: 960px)': style };
    },
};

export const ScrollBarStyles: GriffelStyle = {
    overflowY: 'scroll',
    '&:hover': {
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorScrollbarOverlay,
            visibility: 'visible',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorNeutralBackground1,
            WebkitBoxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
            visibility: 'visible',
        },
    },
};

export const SharedStyles: Record<string, GriffelStyle> = {
    scroll: {
        height: '100%',
        ...ScrollBarStyles,
    },
};

export const useDialogClasses = makeStyles({
    root: {
        height: '515px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
    },
    scopes: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '5px',
        paddingLeft: '20px',
    },
    error: {
        color: '#d13438',
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        rowGap: '10px',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minWidth: '175px',
    },
});
