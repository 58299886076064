/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import CacheUtils from '../utils/CacheUtils';
import { BaseService, getCacheQueryString } from './BaseService';

export interface IUseBrowserCache {
    user: string;
    useBrowserCache: boolean;
}

export interface IAppConfigValue {
    Value: string;
    Category?: string;
    CategoryTitle?: string;
}

export class AppConfigService extends BaseService {
    public getConfigSingleValueAsync = async (
        key: string,
        accessToken: string,
        browserCache: IUseBrowserCache,
    ): Promise<IAppConfigValue> => {
        const dataCacheKey = browserCache.user ? `getConfigSingleValueAsync.${browserCache.user}` : '';
        const cachedData: any =
            browserCache.useBrowserCache && dataCacheKey ? CacheUtils.getStoredDataByKey(dataCacheKey) : null;

        if (cachedData) {
            return cachedData;
        } else {
            const result = await this.getResponseAsync<IAppConfigValue>(
                {
                    commandPath: `appConfig/getConfigValue?key=${key}${getCacheQueryString('clearCache', '&')}`,
                    method: 'GET',
                },
                accessToken,
            );

            if (browserCache.useBrowserCache && dataCacheKey) {
                CacheUtils.setStoredDataByKey(dataCacheKey, result, CacheUtils.dataCacheLongExpiresMin);
            }

            return result;
        }
    };

    public getConfigMultipleValues = async (
        key: string,
        accessToken: string,
        browserCache: IUseBrowserCache,
    ): Promise<IAppConfigValue[]> => {
        const dataCacheKey = browserCache.user ? `getConfigMultipleValues.${browserCache.user}` : '';
        const cachedData: any =
            browserCache.useBrowserCache && dataCacheKey ? CacheUtils.getStoredDataByKey(dataCacheKey) : null;

        if (cachedData) {
            return cachedData;
        } else {
            const result = await this.getResponseAsync<IAppConfigValue[]>(
                {
                    commandPath: `appConfig/getConfigValues?key=${key}${getCacheQueryString('clearCache', '&')}`,
                    method: 'GET',
                },
                accessToken,
            );

            if (browserCache.useBrowserCache && dataCacheKey) {
                CacheUtils.setStoredDataByKey(dataCacheKey, result, CacheUtils.dataCacheLongExpiresMin);
            }

            return result;
        }
    };
}
